import React from 'react'
import { useUserContext } from '../context/UserContext'
import { Block, Heading,  Paragraph } from 'suomifi-ui-components';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

type Props = {}

const LogoutView = (props: Props) => {
  const userContext = useUserContext();
  // todo
  userContext.setIsLoggedIn(false);
  userContext.setName(null);

  sessionStorage.clear();
  localStorage.clear();

  return (
    <Block>
      <Heading variant="h1">{t('logoutView.title')}</Heading>  
      <Paragraph>
        <Link to='/'>{t('logoutView.redirect')}</Link>
      </Paragraph>
    </Block> 
  )
}

export default LogoutView