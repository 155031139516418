import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Block } from 'suomifi-ui-components';

function Footer() {
  const { t } = useTranslation();
  return (
    <Block variant='footer'>
       <Box sx={{ maxWidth: "1200px", paddingRight: "10px", paddingLeft: "10px", marginLeft: "auto", marginRight: "auto", textAlign: "center", padding: "30px" }}>       
        <Link to="/data-protection" onClick={(e) => { e.preventDefault(); alert("not implemented yet. Keksejä käytetään kirjautumiseen, ei muuhun.")}}>{t("footer.dataProtection")}</Link>
        &nbsp;|&nbsp;
        <Link to="/accesibility-statement" onClick={(e) => { e.preventDefault(); alert("not implemented yet.")}}>{t("footer.accessibilityStatement")}</Link>
        &nbsp;|&nbsp;
        <Link to="/contact" onClick={(e) => { e.preventDefault(); alert("not implemented yet.")}}>{t("footer.contactInformation")}</Link>
      </Box>
    </Block>
  )
}

export default Footer