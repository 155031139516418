import { useState } from 'react';
import './App.css';

import { ThemeProvider, createTheme } from '@mui/material';
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { IUserData, UserContext } from './context/UserContext';
import LoginView, { loginPostAction } from './views/LoginView';
import EudtrcView, { sendToCompanyWalletPostAction } from './views/EudtrcView';
import Home from './views/Home';
import LogoutView from './views/LogoutView';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(0, 53, 122)'
    }
  },
  typography: {
    h1: { fontSize: '2em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    h2: { fontSize: '1.75em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    h3: { fontSize: '1.5em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    h4: { fontSize: '1.25em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    fontFamily: '"Source Sans Pro", "Helvetica Neue", Arial, sans-serif',
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '1px solid #ccc',
          padding: '5em 3em 1em 3em'
        }
      },
      defaultProps: {
        elevation: 0,
        square: true,
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: '1px solid #ccc',
          padding: '1em 1em 1em 1em'
        }
      }
    }
  }
});



function App() {

  const username = sessionStorage.getItem("user");

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(username!=null);
  const [name, setName] = useState<string|null>(username);

  const userContext: IUserData = { isLoggedIn: isLoggedIn, setIsLoggedIn: setIsLoggedIn, setName: setName, name: name};

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      handle: "route.home",
      children: [
        {
          path: "login",
          element: <LoginView />,
          action: loginPostAction(userContext)
        },
        {
          path: "logout",
          element: <LogoutView />
        },
        {
          path: "eudtrc",
          element: <EudtrcView />,
          handle: "route.eudtrc",
          action: sendToCompanyWalletPostAction
        },
        // {
        //   path: "company",
        //   element: <CompanyView />
        // },
      ],
    },
  ]);
  
  return (
    <ThemeProvider theme={theme}>
      <UserContext.Provider value={userContext}>
        <RouterProvider router={router} />
      </UserContext.Provider>
    </ThemeProvider>
  );
}

export default App;
