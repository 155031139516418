import { useTranslation } from 'react-i18next';
import { useMatches, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbLink } from 'suomifi-ui-components';

type Props = {

}

interface ICrumbInfo {
  name: string,
  pathname: string
}

const Breadcrumbs = (props: Props) => {
  const params = useParams();
  const matches = useMatches();
  const { t } = useTranslation();

  // TODO fix this solution
  const crumbInfo: ICrumbInfo[] = [];
  for(const match of matches) {
    if (match.handle as string|undefined) {
      let name: string = match.handle as string;
      if (name.startsWith(":")) {
        name = params[name.substring(1)] as string;
      } 
      crumbInfo.push({ name: name, pathname: match.pathname});
    }
  }

  return (<Breadcrumb aria-label='Murupolku'>
    { crumbInfo.map((x, i) => 
      <BreadcrumbLink key={i} href={x.pathname} current={i===crumbInfo.length-1}>
        { t(x.name) }
      </BreadcrumbLink>
    )}
  </Breadcrumb>
  )
}

export default Breadcrumbs;
