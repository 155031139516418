import { Box, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { Form, redirect, useActionData, useNavigate, useNavigation } from 'react-router-dom'
import { Button, IconLogin, TextInput, Notification, LoadingSpinner, Heading } from 'suomifi-ui-components'
import { IUserData, useUserContext } from '../context/UserContext'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'


export interface IActionData {
  error?: string,
  datetime: Date
}


type Props = {}

debugger;

let state =  window.crypto.randomUUID().replace(/-/g,"");
let interval: any;

const LoginView = (props: Props) => {
  const userContext = useUserContext();
  const navigate = useNavigate();
  const actionData = useActionData() as IActionData | undefined;
  const navigation = useNavigation();
  const { t } = useTranslation();
  //const loading = navigation.state === "loading";
  const submitting = navigation.state === "submitting";

  const [hideErrorDateTime, setHideErrorDateTime] = useState<Date>(new Date()); // TODO
  const [ redirectUri, setRedirectUri ] = useState<string|undefined>(undefined);


  useEffect(() => {

      fetch(`https://test.minisuomi.fi/api/verifiers/verifier/createPresentationRequest/pid?state=${state}&clientId=vero`).then(x=> {
        x.json().then(presentationDefinition => {
        fetch(`https://test.minisuomi.fi/api/verifiers/verifier/initiate-transaction/`, 
          { 
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            }, 
            body: JSON.stringify(presentationDefinition)
          }).then(x => {
            x.json().then((json: any) => {
              setRedirectUri(json.responseUri);
            })
          })
        }).catch(x => console.log(x));
      })
    //}
    
  }, []);
 
  useEffect(() => {
    if (userContext.isLoggedIn) {
      const searchParams = new URLSearchParams(document.location.search)
      const redirectpath = searchParams.get("redirect");
      setRedirectUri(undefined);
      state = window.crypto.randomUUID().replace(/-/g,"");
      return navigate(redirectpath?.startsWith("/") ? redirectpath : "/");
    }
    if (redirectUri) {
      interval = setInterval(() => {
        if (!window.window.location.pathname.startsWith("/login")) {
          clearInterval(interval);
          return;
        }
        fetch("https://test.minisuomi.fi/api/verifiers/verifier/poll/"+state).then(x => x.json()).then(x => {
          if (x.verified) {
            clearInterval(interval);
            userContext.setName(x.credentialSubject.given_name + " " + x.credentialSubject.family_name);
            userContext.setIsLoggedIn(true);
            setRedirectUri(undefined);
            state = window.crypto.randomUUID().replace(/-/g,"");
          }
        });
      }, 3000)
    }
  }, [redirectUri, userContext, navigate]);
  

  return (   
    <Box
      sx={{
        marginTop: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Heading variant='h1'>
        {t("loginView.title")}
      </Heading>

      <div>
      { redirectUri &&
      <>
        {/* <p>{decodeURIComponent(redirectUri)}</p>
        <p>{redirectUri}</p> */}
        
        <div style={{ height: "auto", margin: "0 auto", maxWidth: 192, width: "100%" }}>
          <a href={redirectUri}>
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={ redirectUri }
              viewBox={`0 0 256 256`}
              />
            </a>  
          </div> 
        </>
        }
      </div>


      {/* <Box component="form" method="post" action={location.pathname} onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} width={320}> */}
      <Form method='post'>
        <TextInput className='input--custom'
          required
          fullWidth
          id="email"
          name="email"
          autoComplete="email"
          autoFocus labelText={t("loginView.email")} />
        <TextInput className='input--custom'
          required
          fullWidth
          name="password"
          labelText={t("loginView.password")}
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <br />
        <Button iconRight={<IconLogin />}
          disabled = {submitting} 
          type="submit"
          fullWidth
          variant="default"              
        >
          {t("login")}
        </Button>

      </Form>
      {/* </Box> */}

      <Box height={60} mt={3}>

        { submitting && <LoadingSpinner
          forwardedRef={undefined}
          status="loading"
          text="lataa"
          textAlign="right"
          variant="normal"
        />
        }
        <Grid container maxWidth="xs">
          <Grid item>
            { actionData && actionData.datetime > hideErrorDateTime && actionData.error && <Notification
              closeText="Sulje"
              headingText={t("loginView.failed")}
              status="error"
              onCloseButtonClick={() => { setHideErrorDateTime(new Date()) }}
            >
              {actionData.error}
            </Notification>
            }
          </Grid>

        </Grid>
      </Box>
          
    </Box>
  );
}

export const loginPostAction = (userContext: IUserData) => async ({ request }: any) => {

  const data = Object.fromEntries(await request.formData());
  const username = (data.email as string).split("@")[0].split(".").map(x => x && x.length > 1 ? x[0].toUpperCase() + x.substring(1) : "").join(" ").replace("  ", " ");

  try {
    const delay = (ms:any) => new Promise(res => setTimeout(res, ms));
    
    await delay(500); // TODO remove delay and do real login here: const result = await axios.post<any>("/login", data);//.then((res) => { });

    sessionStorage.setItem("user", username);

    userContext.setName(username);
    userContext.setIsLoggedIn(true);
    const searchParams = new URLSearchParams(document.location.search)
    const redirectpath = searchParams.get("redirect");
    return redirect(redirectpath?.startsWith("/") ? redirectpath : "/");
  }
  catch (err: any) {
    return { error: err.message, datetime: new Date() } as IActionData
  }
}

export default LoginView