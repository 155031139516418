
import { useTranslation } from 'react-i18next'
import { Form, useActionData, useLocation, useNavigation } from 'react-router-dom'
import { Block, Button, Heading, Label, Text, LoadingSpinner, Paragraph, TextInput, IconBuildingAdministrative } from 'suomifi-ui-components'
import IEudtrc from '../models/Eudtrc/IEudtrc'
import { WalletsAPI } from '../api/wallets-api/wallets-api'
import { useEffect } from 'react'
import { Box, Paper } from '@mui/material'

const VERO_API_URL = process.env.REACT_APP_VERO_API_URL || process.env.VERO_API_URL || "/api";
const VERO_API_KEY = process.env.REACT_APP_VERO_API_KEY || process.env.VERO_API_KEY || "";

type Props = {}

interface IActionData {
  success: boolean|undefined,
  message: string|undefined,
  error: string|undefined
}

const EudtrcView = (props: Props) => {
  const { t } = useTranslation(); 
  const navigation = useNavigation();
  const data = useActionData() as IActionData;
  const loading = navigation.state === "loading";
  const submitting = navigation.state === "submitting";
  
  // useEffect(() => {

  // }, [loading])

  return (
    <Block>
      <Heading variant={'h1'}>{t("eudtrcView.title")}</Heading>

      <Paragraph>{t("eudtrcView.description")}</Paragraph>
      
      <Paragraph>{t("eudtrcView.formInfo")}</Paragraph>
      <Form method="post">
        <TextInput readOnly={false} className='shorter' name='companyCode' labelText={t("eudtrcView.companyCode")} defaultValue="7011367-6"></TextInput>
        <TextInput readOnly={false} className='wider' name='walletAddress' labelText={t("eudtrcView.walletAddress")} defaultValue="did:web:yrityslompakko.minisuomi.net:7011367-6"></TextInput>
      
        <Button disabled={submitting||loading} variant="default" type="submit">{t("eudtrcView.sendToOrganizationWallet")}</Button>
      </Form>
      <Box mt={1}>
        { (loading || submitting || data?.success === true || data?.success === false) &&   
        <LoadingSpinner textAlign='right' text={ t(data?.success ? "eudtrcView.success" : "sending")} status={data?.success ? "success" : (data?.success === false ? "failed" : "loading")} /> 
        }
      </Box>
      
      <p>{ data?.error||"" }</p>
    </Block>
  )
}


export const sendToCompanyWalletPostAction = async ({ request }: any) => {

  const data = Object.fromEntries(await request.formData());

  try {
    // TODO/NOTE do not do this on real production site with real data, it reveals api key on the browser query.
    const walletsApi = new WalletsAPI({ baseUrl: VERO_API_URL, baseApiParams: { headers: { "x-functions-key": VERO_API_KEY }}}); 
    const result = await walletsApi.vero.veroControllerSendEudtrcWithWebDid({ webDid: data.walletAddress }, data.companyCode);
    if (result.ok) {
      return { message: "Todistus lähetetty", success: true } as IActionData;  
    }
    return { message: "Todistus lähetetty?", success: false } as IActionData;
  }
  catch (err: any) {
    return { error: err?.error?.message || err?.message || "failed", success: false } as IActionData
  }
}

export default EudtrcView