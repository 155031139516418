import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18next
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .init({
        fallbackLng: "en",
        resources: {
            en: {
                translation: {
                    appName: "Mini-Tax",  
                    eudtrcView: {
                        companyCode: "Company ID",
                        sendToOrganizationWallet: "Send residence certificate to organization wallet",
                        description: "Mini-Tax office can issue electronic tax residence certificates to digital wallets.",
                        formInfo: "Please give your company wallet address:",
                        success: "Certificate has been sent to the wallet",
                        title: "Tax residence certificate",
                        walletAddress: "Wallet address (domain or web-did)",
                    }, 
                    footer: {
                        accessibilityStatement: "accessibility statement",
                        contactInformation: "contact information",
                        dataProtection: "data protection"
                    }, 
                    gotoResidenceCertificate: "Apply for a tax residence certificate",
                    loading: "Loading...",
                    login: "Log in",                   
                    loginView: {
                        email: "e-mail",
                        password: "password",
                        title: "Identification",
                        failed: "Login failed",
                    },
                    logout: "Log out",
                    logoutView: {
                        title: "You have been logged out",
                        redirect: "Continue to frontpage",
                    },
                    pageDescription: "This is a demo site for the <1>Real time economy</1> project's experiments.",
                    pageInfo: "Log in to demonstrate residence certificate with wallets.",
                    route: {
                        eudtrc: "Residence certificate",
                        home: "Frontpage",
                    },
                    sending: "Sending...",
                    ydUrl: "https://www.yrityksendigitalous.fi/en/",
                    welcome: "Welcome to Mini-Tax",
                }
            },
            fi: {
                translation: {
                    appName: "Mini-Vero",
                    eudtrcView: {
                        companyCode: "Y-tunnus",
                        sendToOrganizationWallet: "Lähetä kotipaikkatodistus yrityksen lompakkoon",
                        description: "Mini-Vero voi myöntää verotuksellisia kotipaikkatodistuksia digitaaliseen lompakkoon.",
                        formInfo: "Syötä yrityksen lompakon tiedot:",
                        success: "Todistus lähetetty lompakkoon.",
                        title: "Hae kotipaikkatodistusta",
                        walletAddress: "Lompakon osoite (www-sivu tai web-did)",
                    },
                    footer: {
                        accessibilityStatement: "saatavuusseloste",
                        contactInformation: "yhteystiedot",
                        dataProtection: "tietosuoja"
                    },
                    gotoResidenceCertificate: "Siirry kotipaikkatodistuksen hakuun",
                    loading: "Lataa...",
                    login: "Kirjaudu",
                    loginView: {
                        email: "sähköposti",
                        password: "salasana",
                        title: "Tunnistautuminen",
                        failed: "Kirjautuminen epäonnistui",
                    },
                    logout: "Kirjaudu ulos",
                    logoutView: {
                        title: "Olet uloskirjautunut",
                        redirect: "Siirry etusivulle",
                    },
                    pageDescription: 'Tämä on demosivusto, joka on luotu <1>Yrityksen digitalous</1> -hankkeen kokeiluja varten.',
                    pageInfo: "Kirjaudu sisään demotaksesi kotipaikkatodistuksen pyytämistä ja lähettämistä lompakkojen avulla.",
                    route: {
                        eudtrc: "Kotipaikkatodistus",
                        home: "Etusivu"
                    },
                    sending: "Lähettää...",
                    ydUrl: "https://www.yrityksendigitalous.fi/",
                    welcome: "Tervetuloa Mini-Veroon!",
                }
            },
            sv: {
                translation: {
                    appName: "Mini-Skatta",
                    eudtrcView: {
                        sendToOrganizationWallet: "Skicka hemvistcertifikat till företagets plånbok",
                        companyCode: "Företags ID",
                        description: "Mini-Skatte kan utfärda hemvistcertifikat.",
                        formInfo: "Ange företagets plånboksadress:",
                        success: "Bostadsbeviset har skickats till plånboken.",
                        title: "Ansök om hemvistcertifikat",
                        walletAddress: "Plånboksadress (www-sida eller webb-gjort)",
                    },
                    footer: {
                        accessibilityStatement: "tillgänglighetsförklaring",
                        contactInformation: "kontaktinformation",
                        dataProtection: "dataskydd"
                    },        
                    gotoResidenceCertificate: "Ansök om hemvistcertifikat",
                    loading: "",// "Laddar...",
                    login: "Logga in",
                    loginView: {
                        email: "e-post",
                        password: "lösenord",
                        title: "Identifiering",
                        failed: "Inloggningen misslyckades",
                    },
                    logout: "Logga ut",
                    logoutView: {
                        title: "Du är utloggad",
                        redirect: "Fortsätt till startsidan",
                    },
                    pageDescription: "Detta är en demowebbplats för att testa experiment med <1>Realtidsekonomi</1>.",
                    pageInfo: "Logga in på demo för att begära och skicka bevis på hemvist med hjälp av plånböcker.",
                    route: {
                        eudtrc: "Hemvistcertifikat",
                        home: "Hemsida"
                    },
                    sending: "Sändning...",
                    ydUrl: "https://www.yrityksendigitalous.fi/sv/",
                    welcome: "Välkommen till Mini-Skatta",
                }
            }
        }
    })